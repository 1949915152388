import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MemoAction } from 'src/app/store/approval-request/approval-request.models';

@Component({
  selector: 'app-approval-confirm-modal',
  templateUrl: './approval-confirm-modal.component.html',
  styleUrls: ['./approval-confirm-modal.component.scss'],
})
export class ApprovalConfirmModalComponent {
  @ViewChild('confirmationModal', { static: true })
  confirmationModal: ElementRef;

  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  actionType: MemoAction;
  reason: string | null;
  enableReason: boolean;
  canSkipReason: boolean;

  constructor(
    public modalService: NgbModal,
    public translate: TranslateService,
    public store: Store,
  ) {}

  open(enableReason: boolean, canSkipReason = false): void {
    this.reason = null;
    this.enableReason = enableReason;
    this.canSkipReason = canSkipReason;
    this.actionType = this.store.selectSnapshot<MemoAction>((s) => {
      return s.approvalRequest.actionPayload.action;
    });
    this.modalService.open(this.confirmationModal, {
      size: 'size-smax56',
      backdrop: 'static',
      centered: true,
    });
  }

  skip(): void {
    this.reason = null;
    this.submit();
  }

  submit(): void {
    this.modalService.dismissAll();
    this.confirm.emit(this.reason);
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  get contentText() {
    if (this.actionType === 'approve') {
      return this.translate.instant('APPROVAL.APPROVER-COMMENT');
    } else if (this.actionType === 'reject') {
      return this.translate.instant('APPROVAL.Reason to return');
    } else {
      return this.translate.instant('APPROVAL.Reason to terminate');
    }
  }
}

export interface ConfirmModalOptions {
  confirmEmitValue?: any;
  enableReason?: boolean;
}
