<ng-template #confirmationModal>
  <app-modal-confirmation-std4-skin [dismissText]="(canSkipReason ?'COMMON.Skip' : 'COMMON.Cancel') | translate"
      [header]="contentText | translate"
      [submitText]="'COMMON.Confirm' | translate"
      (dismiss)="canSkipReason ? skip() : close()"
      (submitted)="submit()"
      [isSubmitDisabled]="enableReason && !reason"
      [bodyNotDesignDownload]="true">
    <ng-template>
      <div class="text-center"
          *ngIf="!enableReason">
        <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
      </div>
      <div *ngIf="enableReason">
        <div class="form-group mt-2">
          <textarea class="form-control"
              [placeholder]="('MEMOS.MESSAGE' | translate) + '...'"
              rows="4"
              fz-elastic
              [(ngModel)]="reason">
        </textarea>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>